.WatchlistTable {
    padding: 0px 0px !important;
}

.WatchlistTable th {
    padding: 0px 0px !important;
}

.WatchlistTable tr td {
    padding: 0px 0px 0px 0px !important;
}

.WatchlistExpandLine {
    background-color: #FFFFFF !important;
    padding-bottom: 2px !important;
}

.WatchlistExpandLine td {
    background-color: #FFFFFF !important;
    border-bottom: 1px solid #DDDDDD !important;
    padding-bottom: 2px !important;
}

.WatchlistTableNormal {
    padding: 4px 2px !important;
}

.WatchlistTableNormal th {
    padding: 4px 2px !important;
}

.WatchlistTableNormal tr td {
    padding: 4px 2px 4px 2px !important;
}