
.SignalTable {
    padding: 0px 0px !important;
    border: 1px solid #000000 !important;
}

.SignalTable {
    padding: 0px 0px !important;
}

.SignalTable th {
    padding: 0px 0px !important;
}
.SignalTable tr td {
    padding: 0px 0px 0px 0px !important;
    border: 1px solid #000000;
}
.SignalTable tbody {
    padding: 0px 0px 0px 0px !important;
    border: 1px solid #000000 !important;
}

.SignalTableNormal {
    padding: 2px 2px !important;
}
.SignalTableNormal th {
    padding: 2px 2px !important;
}
.SignalTableNormal tr td {
    padding: 2px 2px 2px 2px !important;
}