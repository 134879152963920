.ant-tabs-ink-bar{
    background-color: transparent !important;
  }
   
  .ant-tabs-top .ant-tabs-ink-bar-animated:before{
    content: " ";
    background-color: red;
    width: 50px;
    height: 2px;
    position: absolute;
    margin: auto;
    margin-left: 25%;
   
  }

  .ant-tabs-tab {
    border-bottom-width: 3px !important;
    border-bottom-color: #1890FF !important;
  }

  .ant-tabs-tab::before {
    border-bottom-width: 3px !important;
    border-bottom-color: #1890FF !important;
  }

  .ant-tabs-tab-active {
    background-color: white !important;
    border-left-width: 3px !important;
    border-left-color: #1890FF !important;
    border-top-width: 3px !important;
    border-top-color: #1890FF !important;
    border-right-width: 3px !important;
    border-right-color: #1890FF !important;
    border-bottom-width: 3px !important;
    border-bottom-color: #fff !important;
    margin-top: 3px !important;
    box-shadow: 3px 3px 6px #444
  }

.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom-width: 3px !important;
    border-bottom-color: #1890FF !important;

  }