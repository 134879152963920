.ant-drawer-wrapper-body {
    z-index: 9000 !important;
    overflow: visible !important;
    position: absolute !important;
    display: block !important;
    box-shadow: 0px 0px 0px #FFFFFF !important;
    height: 0% !important;
}


.ant-drawer-body {
    z-index: 9000 !important;
    overflow: visible !important;
    position: fixed !important;
    padding: 0 0 0 0 !important;
    background-color: white !important;
    box-shadow: 8px 12px 15px #DDDDDD !important;

}